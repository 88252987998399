import { SimpleCard } from '../../common/components/livechat/card/SimpleCard';
import styled from 'styled-components';

const List = styled.ul`
  margin: 0 auto;
  font-size: 14px;
  padding-left: 0;
  list-style: inside decimal;
`;

const Item = styled.li`
  margin: 0 auto;
  font-size: 14px;
`;

const Text = styled.h2`
  margin-top: 0;
  margin-bottom: 0.5rem;
  font-size: 14px;
`;

const ItemLink = styled.a`
  text-decoration: underline;
  color: #5a5a5a;
`;

export const InstructionsCard = () => {
  const info = (
    <>
      <Text>Follow the guide to connect your Instagram.:</Text>
      <List>
        <Item>
          <ItemLink target="_blank" href="https://onelayer.notion.site/Live-Chat-Instagram-Tutorial-98f2ad9f3c51484ab242af0659c8043a">Instagram Direct Messages app set-up instructions</ItemLink>
        </Item>
      </List>
    </>
  );

  return <SimpleCard title="Instructions" info={info}></SimpleCard>;
};
