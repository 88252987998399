import axios from 'axios';

export interface OrganizationDto {
  id: string;
  status: 'LIVECHAT_READY' | 'FACEBOOK_READY' | 'INTEGRATED';
  instagram: {
    pageId: string;
  } | null;
  livechat: {
    routeToGroupId: number | null;
  } | null;
}

export interface InstagramAccountDto {
  id: string;
  name: string;
}

export interface InstagramAccountsResponse {
  items: InstagramAccountDto[];
}


export interface Group {
  id: number;
  name: string;
}

export interface GroupsResponse {
  items: Group[];
}

export const createApiClient = () => {
  const api = axios.create({
    baseURL: '/api/livechat-instagram',
    withCredentials: true,
  });

  api.interceptors.response.use(
    response => response.data,
    error => Promise.reject(error)
  );

  const getOrganization = () =>
    api.get<OrganizationDto, OrganizationDto>('/organization');

  const getPages = () => api.get<InstagramAccountsResponse, InstagramAccountsResponse>('/pages');

  const setPage = (pageId: string) => api.put('/set-page', { pageId });

  const getGroups = () => api.get<GroupsResponse, GroupsResponse>('/groups');

  const setGroup = (routeToGroupId: number | null) => api.put('/set-route-group', { routeToGroupId });

  const deleteFacebook = () => api.delete('/disconnect-facebook');

  return { getOrganization, getPages, setPage, getGroups, setGroup, deleteFacebook };
};
