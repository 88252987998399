import { Button, INotificationContext } from '@livechat/design-system';
import '@livechat/design-system/dist/design-system.css';
import { useCallback, useState } from 'react';
import { useMutation, useQueryClient } from 'react-query';
import { OrganizationDto } from '../api/client';
import { SimpleCard } from '../../common/components/livechat/card/SimpleCard';
import { Queries } from '../constants/queries';
import { useApiClient } from '../hooks/useApiClient';

const useInstagram = ({
  onDisconnectSuccess,
  onConnectSuccess,
}: {
  onDisconnectSuccess?: () => unknown;
  onConnectSuccess?: () => unknown;
}) => {
  const [facebookConnectionInProgress, setFacebookConnectionInProgress] = useState(false);
  const client = useApiClient();

  const { mutate: disconnectFacebook, isLoading: disconnectFacebookLoading } = useMutation(client.deleteFacebook, {
    onSuccess: () => {
      onDisconnectSuccess?.();
    },
  });

  const startFacebookConnection = useCallback(() => {
    setFacebookConnectionInProgress(true);
    const facebookWindow = window.open('/api/livechat-instagram/connect/facebook');
    let count = 0;
    const interval = setInterval(() => {
      count = count + 1;
      if (count > 6000) {
        clearInterval(interval);
        setFacebookConnectionInProgress(false);
      }
      if (facebookWindow?.closed) {
        clearInterval(interval);
        setFacebookConnectionInProgress(false);
        onConnectSuccess?.();
      }
    }, 50);
    facebookWindow?.focus();
  }, [onConnectSuccess]);

  return {
    facebookConnectionInProgress,
    startFacebookConnection,
    disconnectFacebook,
    disconnectFacebookLoading,
  };
};

export interface ConnectInstagramCardProps {
  organization: OrganizationDto;
  notificationSystem: INotificationContext;
}

export const ConnectInstagramCard = ({ organization, notificationSystem }: ConnectInstagramCardProps) => {
  const queryClient = useQueryClient();
  const { facebookConnectionInProgress, startFacebookConnection, disconnectFacebook, disconnectFacebookLoading } =
    useInstagram({
      onConnectSuccess: () => {
        queryClient.invalidateQueries(Queries.getOrganization);
      },
      onDisconnectSuccess: () => {
        notificationSystem.add?.({
          type: 'toast',
          autoHideDelayTime: 5000,
          payload: {
            variant: 'success',
            content: 'Instagram was disconnected successfully!',
            horizontalPosition: 'center',
            verticalPosition: 'top',
            removable: false,
          },
        });
        queryClient.invalidateQueries(Queries.getOrganization);
      },
    });

  if (organization.status === 'LIVECHAT_READY') {
    return (
      <SimpleCard
        title="Connect Account"
        info="Please use the following button to connect your Instagram and Livechat accounts"
        footer={
          <Button
            loading={facebookConnectionInProgress}
            onClick={startFacebookConnection}
            kind="primary"
            loaderLabel="In Progress"
          >
            Connect Instagram
          </Button>
        }
      />
    );
  }

  return (
    <SimpleCard
      title="Disconnect Account"
      info="You can use the following button to disconnect your Instagram and Livechat accounts"
      footer={
        <Button
          loading={disconnectFacebookLoading}
          onClick={() => disconnectFacebook()}
          kind="destructive"
          loaderLabel="In Progress"
        >
          Disconnect Instagram
        </Button>
      }
    />
  );
};
