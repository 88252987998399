import { QueryClient, QueryClientProvider } from 'react-query';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { QueryParamProvider } from 'use-query-params';
import { config } from './config';
import { ConfigProvider } from './common/hooks/useConfig';
import { Home } from './common/pages/Home';
import { Settings as LivechatInstagramSettings } from './livechat-instagram/pages/Settings';
import { Settings as LivechatTwitterCommentsSettings } from './livechat-twitter-comments/pages/Settings';

console.log('Using config', config);

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      cacheTime: 30 * 5 * 1000,
      retry: false,
      staleTime: 30 * 5 * 1000,
    },
  },
});

export const App = () => {
  return (
    <ConfigProvider value={config}>
      <QueryParamProvider>
        <QueryClientProvider client={queryClient}>
          <BrowserRouter >
            <Routes>
              <Route path="/livechat-instagram/settings" element={<LivechatInstagramSettings />} />
              <Route path="/livechat-twitter-comments/settings" element={<LivechatTwitterCommentsSettings />} />
              <Route path="/" element={<Home />} />
            </Routes>
          </BrowserRouter>
        </QueryClientProvider>
      </QueryParamProvider>
    </ConfigProvider>
  );
};
