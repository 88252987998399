import { Button, INotificationContext } from '@livechat/design-system';
import '@livechat/design-system/dist/design-system.css';
import { useCallback, useEffect, useState } from 'react';
import { useMutation, useQueryClient } from 'react-query';
import { OrganizationDto } from '../api/client';
import { SimpleCard } from '../../common/components/livechat/card/SimpleCard';
import { Queries } from '../constants/queries';
import { useApiClient } from '../hooks/useApiClient';

const useTwitter = ({
  organization,
  onDisconnectSuccess,
  onConnectSuccess,
  onConnectRetry,
}: {
  organization: OrganizationDto;
  onDisconnectSuccess?: () => unknown;
  onConnectSuccess?: () => unknown;
  onConnectRetry?: () => unknown;
}) => {
  const [twitterConnectionInProgress, setTwitterConnectionInProgress] = useState(false);
  const [interval, storeInterval] = useState<any>();
  const client = useApiClient();

  const { mutate: disconnectTwitter, isLoading: disconnectTwitterLoading } = useMutation(client.deleteTwitter, {
    onSuccess: () => {
      onDisconnectSuccess?.();
    },
  });

  useEffect(() => {
    if (organization.status === 'INTEGRATED' && interval) {
      clearInterval(interval);
      storeInterval(undefined);
      setTwitterConnectionInProgress(false);
      onConnectSuccess?.();
    }
  }, [interval, onConnectSuccess, organization.status]);

  const startTwitterConnection = useCallback(() => {
    window.open('/api/livechat-twitter-comments/connect/twitter');
    if (interval) {
      return;
    }
    setTwitterConnectionInProgress(true);
    let count = 0;
    const pollInterval = setInterval(() => {
      count = count + 1;
      if (count > 2000) {
        clearInterval(pollInterval);
        storeInterval(undefined);
        setTwitterConnectionInProgress(false);
      }
      onConnectRetry?.();
    }, 1000);
    storeInterval(pollInterval);
  }, [interval, onConnectRetry]);

  return {
    twitterConnectionInProgress,
    startTwitterConnection,
    disconnectTwitter,
    disconnectTwitterLoading,
  };
};

export interface ConnectTwitterCardProps {
  organization: OrganizationDto;
  notificationSystem: INotificationContext;
}

export const ConnectTwitterCard = ({ organization, notificationSystem }: ConnectTwitterCardProps) => {
  const queryClient = useQueryClient();
  const { startTwitterConnection, disconnectTwitter, disconnectTwitterLoading } = useTwitter({
    organization,
    onConnectRetry: () => {
      queryClient.invalidateQueries(Queries.getOrganization);
    },
    onConnectSuccess: () => {
      queryClient.invalidateQueries(Queries.getOrganization);
    },
    onDisconnectSuccess: () => {
      notificationSystem.add?.({
        type: 'toast',
        autoHideDelayTime: 5000,
        payload: {
          variant: 'success',
          content: 'Twitter was disconnected successfully!',
          horizontalPosition: 'center',
          verticalPosition: 'top',
          removable: false,
        },
      });
      queryClient.invalidateQueries(Queries.getOrganization);
    },
  });

  if (organization.status === 'LIVECHAT_READY') {
    return (
      <SimpleCard
        title="Connect Account"
        info="Please use the following button to connect your Twitter and Livechat accounts"
        footer={
          <Button onClick={startTwitterConnection} kind="primary" loaderLabel="In Progress">
            Connect Twitter
          </Button>
        }
      />
    );
  }

  return (
    <SimpleCard
      title="Disconnect Account"
      info="You can use the following button to disconnect your Twitter and Livechat accounts"
      footer={
        <Button
          loading={disconnectTwitterLoading}
          onClick={() => disconnectTwitter()}
          kind="destructive"
          loaderLabel="In Progress"
        >
          Disconnect Twitter
        </Button>
      }
    />
  );
};
