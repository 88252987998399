import { SelectField, INotificationContext, Button } from '@livechat/design-system';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { Queries } from '../constants/queries';
import { useApiClient } from '../hooks/useApiClient';
import { SimpleCard } from '../../common/components/livechat/card/SimpleCard';
import { ErrorCard } from '../../common/components/livechat/ErrorCard';

export interface PageSelectionCardsProps {
  notificationSystem: INotificationContext
}

export const PageSelectionCard = ({ notificationSystem }: PageSelectionCardsProps) => {
  const [selectedItemKey, setSelectedItemKey] = useState<string | null>(null);

  const client = useApiClient();
  const queryClient = useQueryClient();

  const {
    data: organization,
    isLoading: organizationLoading,
    error: organizationError,
  } = useQuery(Queries.getOrganization, client.getOrganization);

  const {
    data: pages,
    isLoading: pagesLoading,
    error: pagesError,
  } = useQuery(Queries.getPages, client.getPages, { enabled: !!organization });

  const { mutate: setPage, isLoading: setPageLoading } = useMutation(client.setPage, {
    onSuccess: () => {
      notificationSystem.add?.({
        type: 'toast',
        autoHideDelayTime: 5000,
        payload: {
          variant: 'success',
          content: 'Page was saved succesfully!',
          horizontalPosition: 'center',
          verticalPosition: 'top',
          removable: false,
        },
      });
      queryClient.invalidateQueries(Queries.getOrganization);
    },
  });

  const items = useMemo(() => {
    return pages?.items?.map(p => ({ key: p.id, props: { name: p.name, value: p.id } }));
  }, [pages]);

  const selectedPageId = useMemo(() => {
    const item = items?.find(i => i.key === selectedItemKey);
    return item?.props?.value;
  }, [items, selectedItemKey]);

  useEffect(() => {
    if (!organizationLoading && !pagesLoading && organization && items) {
      const key = items.find(i => i.props.value === organization?.instagram?.pageId)?.key ?? null;
      setSelectedItemKey(key);
    }
  }, [pagesLoading, organizationLoading, organization, items]);

  const handleItemSelect = useCallback((key: string) => {
    if (!key) {
      setSelectedItemKey(null);
    } else {
      setSelectedItemKey(key);
    }
  }, []);

  const getItemBody = useCallback((props: { value: string; name: string }) => {
    if (!props) {
      return null;
    }
    return <div id={props.value}>{props.name}</div>;
  }, []);

  const getSelectedItemBody = useCallback((props: { value: string; name: string }) => {
    return <div id={props.value}>{props.name}</div>;
  }, []);

  if (pagesLoading || organizationLoading) {
    return <SimpleCard title="Page Selection" loading info={<></>} footer={<></>}></SimpleCard>;
  }

  if (organizationError) {
    return <ErrorCard error="Unable to load organization" />;
  }

  if (pagesError) {
    return <ErrorCard error="Unable to load pages" />;
  }

  const info = (
    <>
      <SelectField
        id="page"
        items={items || []}
        searchProperty="name"
        onItemSelect={handleItemSelect}
        getItemBody={getItemBody}
        labelText="Select page"
        search
        placeholder="Select page"
        getSelectedItemBody={getSelectedItemBody}
        selected={selectedItemKey || ''}
        searchPlaceholder="Search by name..."
        selectHeader="Select the page connected to your Instagram"
      />
    </>
  );

  const footer = (
    <Button
      loading={setPageLoading}
      kind="primary"
      onClick={() => {
        if (!selectedPageId) {
          return;
        }
        setPage(selectedPageId);
      }}
      disabled={!selectedPageId}
    >
      Save
    </Button>
  );

  return <SimpleCard title="Page Selection" info={info} footer={footer}></SimpleCard>;
};
